<template>
  <div>
    <v-container>
      <v-card class="email-auth-card" dark>
        <v-card-text>
          <div class="back-icon" @click="goBack">
            <v-icon>mdi-chevron-left</v-icon>
          </div>
          <div class="layout column align-center">
            <!-- <div class="v-card__title justify-center display-1 mb-2">
              <v-img :src="logo" max-height="120" max-width="120" />
            </div> -->
            <div class="justify-center text-none sign-login-title"><h3>{{ isLogin?'Log in':'Sign up' }} with Email</h3></div>
          </div>
        </v-card-text>
        <div class="third-box">
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-text-field
              v-model="data.email"
              dense
              outlined
              append-icon="mdi-email"
              name="email"
              label="Email"
              type="email"
              class="input-group--focused"
              required
              :rules="emailRules"
            />
            <v-text-field
              id="password"
              v-model="data.password"
              dense
              outlined
              :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
              name="password"
              label="Password"
              :type="showpassword ? 'text' : 'password'"
              class="input-group--focused"
              required
              :rules="pwdRules"
              @click:append="showpassword=!showpassword"
            />
          </v-form>
        </div>
        <div v-if="errorMsg.length>0" class="errorMsg-box">{{ errorMsg }}</div>
        <v-btn block depressed medium class="text-none login-btn" :disabled="disabledBtn" :loading="loading" @click="submitHandle">
          {{ isLogin?'Log in':'Sign up' }}
        </v-btn>
        <div class="register-bottom">
          <v-row>
            <v-col />

            <v-spacer />

            <v-col v-show="isLogin" align="right">
              <v-btn class="text-none text-decoration-underline" text small @click="pwdHandler">Forgot Password?</v-btn>
            </v-col>
          </v-row>
          <div class="tip-text-box">
            <v-row
              align="center"
              justify="space-around"
            >
              <div v-if="isLogin" class="sign-up-text">Don't have an account?<span @click="switchOp"> Sign up</span></div>
              <div v-else class="login-text">Already have an account?<span @click="switchOp"> Log in</span></div>
            </v-row>
          </div>
        </div>
      </v-card>
    </v-container>
    <emailVerifyDialog ref="emailVerifyDialog" />
  </div>
</template>

<script>
import logo from '@/assets/logo1.png'
import firebase from '@/plugins/firebase'
import emailVerifyDialog from './components/overDialog.vue'

export default {
  name: 'EmailAuth',
  components: {
    emailVerifyDialog
  },
  props: {
    goBack: {
      type: Function,
      required: true,
      default: () => {

      }
    },
    firebaseLogin: {
      type: Function,
      required: true,
      default: async() => {

      }
    }
  },
  data() {
    return {
      logo: logo,
      isLogin: false,
      showpassword: false,
      valid: true,
      data: {
        email: '',
        password: ''
      },
      loading: false,
      disabledBtn: false,
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Email must be valid'
      ],
      pwdRules: [
        v => !!v || 'Password is required',
        v => v && v.trim().length >= 6 || 'Password at least 6 digits'
      ],
      errorMsg: ''
    }
  },
  methods: {
    submitHandle() {
      this.disabledBtn = true
      this.loading = true
      if (!this.isLogin) {
        firebase.auth().createUserWithEmailAndPassword(this.data.email, this.data.password)
          .then(async(userCredential) => {
          // var user = userCredential.user
            // console.log('create')
            // console.log(userCredential)
            await this.sendEmailVerification()
            this.$refs.emailVerifyDialog.controlDialog(true, `One Channel has sent you an email for verification. 
                    You need to verify the email address before logging in your account.`, 'Email verification', true)
            // dialog
            // await this.firebaseLogin(userCredential)
          })
          .catch((error) => {
            console.log(error)
            this.errorMsg = error.message
          }).finally(() => {
            this.loading = false
            this.disabledBtn = false
          })
      } else {
        firebase.auth().signInWithEmailAndPassword(this.data.email, this.data.password)
          .then(async(userCredential) => {
            // console.log('Signed')
            // console.log(userCredential)
            await this.firebaseLogin(userCredential)
          })
          .catch((error) => {
            console.log(error)
            this.errorMsg = error.message
          }).finally(() => {
            this.loading = false
            this.disabledBtn = false
          })
      }
    },
    switchOp() {
      this.isLogin = !this.isLogin
      this.$refs.form.reset()
      this.errorMsg = ''
    },
    pwdHandler() {
      this.$router.push({ path: '/auth/resetpwd' })
    },
    async sendEmailVerification() {
      return new Promise((resolve, reject) => {
        firebase.auth().currentUser.sendEmailVerification()
          .then(() => {
            resolve(true)
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.sign-login-title{
  // margin-top: 30px;
  margin-bottom: 20px;
  color: white;
}

.login-btn{
  letter-spacing: 0.03em;
  font-size: 14px !important;
  font-weight: bold !important;
}

.register-bottom{
  margin-top: 10px;
  .v-btn__content{
      letter-spacing: 0.05em!important;
    }
  .register-text-btn{
    color: #E040FB;
  }
}

.tip-text-box{
  span{
    color: #E040FB;
    cursor: pointer;
  }
  .login-text{
    margin-top: 32px;
  }
}

.email-auth-card{
  padding: 0px 20px 30px 20px;
}

.v-card__subtitle, .v-card__text, .v-card__title{
  padding: unset!important;
}

.back-icon{
  cursor: pointer;
}

.errorMsg-box{
  color: red;
}
</style>
